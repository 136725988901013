import navigation from "./navigation.js";
import section1 from "./section1.js";
import section3 from "./section3.js";
import section6 from "./section6.js";
import modal from "./modal.js";


navigation();
section1();
section3();
modal();
section6();