export function checkError(elem) {
    const regexEmail = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    let errorMessage;

    if (elem.nextSibling && elem.nextSibling.nodeType === Node.ELEMENT_NODE && elem.nextSibling.classList.contains('error-message')) {
        errorMessage = elem.nextSibling;
    } else {
        errorMessage = document.createElement('span');
        errorMessage.classList.add('error-message');
        elem.parentNode.insertBefore(errorMessage, elem.nextSibling);
    }

    if (elem.value === "") {
        elem.classList.add('input-error');
        errorMessage.textContent = 'Ce champ est obligatoire.';
        return true;
    } else if (elem.name === 'email' && !regexEmail.test(elem.value)) {
        elem.classList.add('input-error');
        errorMessage.textContent = 'Veuillez entrer une adresse e-mail valide.';
        return true;
    } else {
        if (errorMessage) {
            elem.parentNode.removeChild(errorMessage);
        }
        elem.classList.remove('input-error');
        return false;
    }
}

export function removeError(event) {
    const elem = event.target;
    const regexEmail = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    let isValid = true;
    
    if (elem.name === 'email') {
        isValid = regexEmail.test(elem.value);
    }
    if (isValid || elem.value !== "") {
        if (elem.nextSibling && elem.nextSibling.nodeType === Node.ELEMENT_NODE && elem.nextSibling.classList.contains('error-message')) {
            elem.parentNode.removeChild(elem.nextSibling);
        }
        elem.classList.remove('input-error');
    }
};


export function removeInputContent(elems) {
    for (const elem of elems) {
        elem.value = '';
    } 
}
