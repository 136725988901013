import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/swiper-bundle.css';

export default function() {
    const swiper2 = new Swiper('.section-3 .container-3 .swiper-container', {
        modules: [Pagination],
        loop: true,
        pagination: {
            el: '.section-3 .container-3 .swiper-container .swiper-pagination',
            clickable: true,
        },
        slidesPerView: 1,
        spaceBetween: 60,
    });
}
