
export default function() {

    let isMenuOpen = false;
    const dropdownMenu = document.querySelector('.dropdown-menu');
    const body = document.body;
    const menuIcon = document.querySelector('.menu-icon svg');
    const mediaQuery = window.matchMedia('(max-width: 925px)');
    const navigation = document.querySelector(".navbar");


    const toggleMenu = () => {
        dropdownMenu.style.animation = !isMenuOpen ? 'slideDown 0.8s ease forwards' : 'slideUp 0.8s ease forwards';
        body.style.overflow = !isMenuOpen ? 'hidden' : '';
        menuIcon.classList.toggle('rotate', !isMenuOpen);
        isMenuOpen = !isMenuOpen;
    };

    document.querySelector('.menu-icon').addEventListener('click', toggleMenu);

    const checkMediaQuery = () => {
        if (!mediaQuery.matches && isMenuOpen) {
            toggleMenu();
        } else if (mediaQuery.matches) {
            navigation.classList.remove('hidden');
        }
    };

    checkMediaQuery();
    mediaQuery.addEventListener('change', checkMediaQuery);


    document.addEventListener("DOMContentLoaded", function() {
        let lastScrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const MIN_SCROLL = 35;
        let isResizing = false;
    
        const checkScroll = () => {
            if (isResizing) {
                return;
            }
            if (mediaQuery.matches) {
                return;
            }
    
            const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
    
            if (Math.abs(lastScrollTop - currentScrollTop) >= MIN_SCROLL) {
                if (currentScrollTop > lastScrollTop && lastScrollTop > 0) {
                    navigation.classList.add("hidden");
                } else {
                    navigation.classList.remove("hidden");
                }
                lastScrollTop = currentScrollTop;
            }
        };
    
        window.addEventListener("scroll", checkScroll);
    
        window.addEventListener("resize", () => {
            isResizing = true;
            setTimeout(() => {
                isResizing = false;
                lastScrollTop = window.pageYOffset || document.documentElement.scrollTop;
            }, 100);
        });
    
        checkScroll();
    });
    
    document.querySelectorAll('.tab, .tab-dropdown').forEach(tab => {
        tab.addEventListener('click', () => {
            const target = tab.dataset.target;
            const targetElement = document.querySelector(`.${target}`);
            if (isMenuOpen) {
                toggleMenu()
            }
            targetElement?.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        });
    });
}