import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/swiper-bundle.css';

// import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';


export default function() {
    // const NbElem = 2;
    // const elemCarousel = document.querySelector('.carousel-container');
    
    // let index = 0;
    // let running = false;

    // function previous() {
    //     if (running) 
    //         return;
    //     running = true;

    //     const prev_index = index;
    //     index = index === 0 ? NbElem - 1 : index - 1;

    //     const elems = document.querySelectorAll(".carousel-image");

    //     elems[index].style.left = `-${elemCarousel.offsetWidth}px`;
    //     elems[index].style.display = "block";
    //     elems[prev_index].style.left = `${elemCarousel.offsetWidth}px`;
    //     elems[index].style.left = `0`;

    //     setTimeout(() => {
    //         elems[prev_index].style.display = 'none';
    //         running = false;
    //     }, 750);
    // }

    // function next() {
    //     if (running)
    //         return;
    //     running = true;

    //     const prev_index = index;
    //     index = (index + 1) % NbElem;

    //     const elems = document.querySelectorAll(".carousel-image");

    //     elems[index].style.left = `${elemCarousel.offsetWidth}px`;
    //     elems[index].style.display = "block";
    //     elems[prev_index].style.left = `-${elemCarousel.offsetWidth}px`;
    //     elems[index].style.left = `0`;

    //     setTimeout(() => {
    //         elems[prev_index].style.display = 'none';
    //         running = false;
    //     }, 750);
    // }
    
    // document.querySelector('.carousel-nav-left').addEventListener('click', previous);
    // document.querySelector('.carousel-nav-right').addEventListener('click', next);

    // Importer Swiper JS et CSS


    // Initialiser Swiper
    const swiper = new Swiper('.section-1 .swiper-container', {
        modules: [Pagination, Navigation],
        loop: true,
        pagination: {
            el: '.section-1 .swiper-container .swiper-pagination',
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });
}

