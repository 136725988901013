import { checkError, removeError, removeInputContent } from "./util.js";
import axios from "axios";


export default function() {
    const formModal = document.getElementById('.form');
    const modalSend = document.querySelector('#form-send');
    const elements = document.querySelectorAll('.form .form-group input, .form .form-group textarea');
    const confirmationModal = document.getElementById('confirmationModal');
    const confirmationText = document.getElementById('confirmation-text');
    const topButton = document.querySelector('.top-button');
    let isRequestInProgress = false;


    topButton.addEventListener('click', () => {
        const top = document.querySelector(`.section-1`);

        top?.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });
    });

    elements.forEach((elem) => {
        elem.addEventListener('input', removeError)
    });

    modalSend.addEventListener('click', (event) => {
        if (isRequestInProgress) {
            return;
        }
        const formData = {};
        for (const elem of elements) {
            if (checkError(elem)) {
                return;
            }
            formData[elem.name] = elem.value;
        }
        isRequestInProgress = true;
        axios.post('https://versailles-entretien.fr/formulaire', formData)
        .then(response => {
            if (response.status === 200) {
                confirmationText.innerText = '✓ Votre message a été envoyé avec succès !';
                confirmationText.style.color ='#00B5ED';
                confirmationModal.style.display = 'flex';
                removeInputContent(elements);
            }
        })
        .catch(error => {
            confirmationText.innerText = 'Une erreur est survenue !';
            confirmationText.style.color = '#e57373';
            confirmationModal.style.display = 'flex';
        })
        .finally(() => {
            isRequestInProgress = false;
        });
    });
}
