import axios from "axios";
import { checkError, removeError, removeInputContent } from "./util.js";

export default function() {
    const formModal = document.getElementById('formModal');
    const confirmationModal = document.getElementById('confirmationModal');
    const confirmationText = document.getElementById('confirmation-text');
    const modalClose = document.querySelectorAll('.modal-close');
    const modalButtons = document.querySelectorAll('.modal-button');
    const modalSend = document.querySelector('#modal-send');
    const elements = document.querySelectorAll('.modal-content .form-group input, .modal-content .form-group textarea');
    let isRequestInProgress = false;

    
    const openModal = () => {
        formModal.style.display = 'flex';
        document.body.classList.add('no-scroll');
    };

    const closeModal = () => {
        formModal.style.display = 'none';
        confirmationModal.style.display = 'none';
        document.body.classList.remove('no-scroll');
    };

    elements.forEach((elem) => {
        elem.addEventListener('input', removeError)
    });
    

    modalButtons.forEach((button) => {
        button.addEventListener('click', openModal);
    });

    modalClose.forEach((closeButton) => {
        closeButton.addEventListener('click', closeModal);
    });

    window.addEventListener('click', (event) => {
        if (event.target === formModal || event.target === confirmationModal) {
            closeModal();
        }
    });

    modalSend.addEventListener('click', (event) => {
        if (isRequestInProgress) {
            return;
        }
        const formData = {};
        for (const elem of elements) {
            if (checkError(elem)) {
                return;
            }
            formData[elem.name] = elem.value;
        }
        isRequestInProgress = true;
        axios.post('https://versailles-entretien.fr/formulaire', formData)
        .then(response => {
            if (response.status === 200) {
                formModal.style.display = 'none';
                confirmationText.innerText = '✓ Votre message a été envoyé avec succès !';
                confirmationText.style.color ='#00B5ED';
                confirmationModal.style.display = 'flex';
                removeInputContent(elements);
            }
        })
        .catch(error => {
            console.error('Erreur:', error);
            formModal.style.display = 'none';
            confirmationText.innerText = 'Une erreur est survenue !';
            confirmationText.style.color = '#e57373';
            confirmationModal.style.display = 'flex';
        })
        .finally(() => {
            isRequestInProgress = false;
        });
    });
}
